<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Menus">
                    <template v-slot:description>
                        <div>
                            <div>import { HbMenu } from 'hummingbird-aviary';</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Meatball Options Menu Example + Code ( *Note: Meatball menus should NOT have any tooltip text on hover )" class="mt-4 mb-6">
            <v-row no-gutters class="pa-4">
                <hb-menu options>

                    <v-list>

                        <v-list-item :ripple="false" @click="setNotification('View')">
                            <v-list-item-title>View</v-list-item-title>
                        </v-list-item>

                        <v-list-item :ripple="false" @click="setNotification('Delete')">
                            <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>

                    </v-list>

                </hb-menu>
            </v-row>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-menu options>

    &lt;v-list>

        &lt;v-list-item :ripple="false" @click="setNotification('View')">
            &lt;v-list-item-title>View&lt;/v-list-item-title>
        &lt;/v-list-item>

        &lt;v-list-item :ripple="false" @click="setNotification('Delete')">
            &lt;v-list-item-title>Delete&lt;/v-list-item-title>
        &lt;/v-list-item>

    &lt;/v-list>
    
&lt;/hb-menu>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Custom Activator Menu Example + Code" class="mt-4 mb-6">
            <v-row no-gutters class="pa-4">
                <hb-menu>

                    <template v-slot:menu-activator>
                        <hb-btn>Any Kind of Activator Can Go Here</hb-btn>
                    </template>

                    <v-list>

                        <v-list-item :ripple="false" @click="setNotification('View')">
                            <v-list-item-title>View</v-list-item-title>
                        </v-list-item>

                        <v-list-item :ripple="false" @click="setNotification('Delete')">
                            <v-list-item-title>Delete</v-list-item-title>
                        </v-list-item>

                    </v-list>

                </hb-menu>
            </v-row>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;hb-menu>

    &lt;template v-slot:menu-activator>
        &lt;hb-btn>Any Kind of Activator Can Go Here&lt;/hb-btn>
    &lt;/template>

    &lt;v-list>

        &lt;v-list-item :ripple="false" @click="setNotification('View')">
            &lt;v-list-item-title>View&lt;/v-list-item-title>
        &lt;/v-list-item>

        &lt;v-list-item :ripple="false" @click="setNotification('Delete')">
            &lt;v-list-item-title>Delete&lt;/v-list-item-title>
        &lt;/v-list-item>

    &lt;/v-list>
    
&lt;/hb-menu>
</pre>
            </hb-card>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-card title="All Other Props, Slots, &amp; Events" class="my-6 pb-1">
            <hb-data-table
                :headers="vuetifyHeaders"
                :items="vuetifyItems"
                class="pa-6"
            >
                <template v-slot:item.resource="{ item }">
                    <hb-link :href="item.resource" target="_blank">{{ item.resource }}</hb-link>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="'You have clicked on ' + notificationName + '.'"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemMenus",
        data: function() {
            return {
                success: false,
                notificationName: '',
                propHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'options', type: 'boolean', default: 'false', description: 'If this is changed to true, the meatball icon button will become the menu activator. This is commonly used in the last column of <hb-data-table> for row item actions. This will disable the "item" slot functionality.' },
                    { name: 'options-hover-type', type: 'boolean', default: 'false', description: 'Applies the correct hover over color when the menu is on a colored background. Options here are "success", "caution", "error", and "guidance".' },
                    { name: 'align-right', type: 'boolean', default: 'false', description: 'If this is changed to true, the menu activator will attempt to float/align right. Useful for when you are using the "options" prop/vertical meatball menu for the last column of <hb-data-table> for row item actions.'},
                    { name: 'disabled', type: 'boolean', default: 'false', description: 'If this is changed to true, the meatball icon button will be disabled and the menu items will not be accessible. Only works if options prop is set to true as well.' }
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'menu-activator', description: 'Sets a custom activator for the menu. Anything can be put here to activate the menu.' }
                ],
                vuetifyHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                    { text: 'Resource', value: 'resource' },
                ],
                vuetifyItems: [
                    { name: 'all other vuetify props, slots, & events', description: 'All the same vuetify props, slots, & events as "<v-menu>" can be used for "<hb-menu>". See the vuetify documentation link in the next column for the list of available props, slots, & events.', resource: 'https://v2.vuetifyjs.com/en/api/v-menu/' },
                ],
            };
        },
        methods: {
            setNotification(item){
                if(item === 'View'){
                    this.notificationName = 'View';
                } else if(item === 'Delete'){
                    this.notificationName = 'Delete';
                } else {
                    this.notificationName = item.name;
                }
                this.success = true;
            }
        }
    }
</script>

<style scoped>

</style>